<template>
  <VCard
    title-class="cursor-pointer"
    actions-class="bg-theme-5"
    @click:title="onClickRedirectToRegistration"
  >
    <template #title>
      <div class="flex items-center">
        <VImage :color="color" class="self-start mr-3" :name="name" />

        <div>
          {{ name }}

          <div class="version">{{ $t("app.versions") }} {{ version }}</div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip :text="$t(`app.${status}`)" :class="getStatusColor(status)" />
    </template>

    <template #content>
      <RegistrationDates :resource="resource" />

      <RegistrationProgress
        v-if="resource?.summary"
        class="mt-2"
        :summary="resource?.summary"
      />
    </template>

    <template #actions>
      <div class="flex justify-end">
        <VButton
          class="btn-primary"
          :label="$t('app.view_registration')"
          @click="onClickRedirectToRegistration"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import RegistrationProgress from "./RegistrationProgress";
import RegistrationDates from "./RegistrationDates";

export default {
  components: {
    VCard,
    VChip,
    VButton,
    VImage,
    RegistrationProgress,
    RegistrationDates
  },
  props: {
    resource: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["click:details"],
  setup(props) {
    // Misc
    const router = useRouter();

    // Composables
    const { getStatusColor } = useColor();
    const { getText } = useTexts();

    // Computed
    const training = computed(() => props.resource?.training);
    const name = computed(() => getText(training.value?.texts, "name"));
    const status = computed(() => props.resource?.status);
    const color = computed(() => training.value?.colour);
    const version = computed(() => training.value?.version);

    // Methods
    const onClickRedirectToRegistration = () => {
      router.push({
        name: "learning-registrations-trainings-details",
        params: {
          id: props.resource?.id
        }
      });
    };

    return {
      onClickRedirectToRegistration,
      name,
      color,
      version,
      status,
      // useColor
      getStatusColor
    };
  }
};
</script>
